import logo from './assets/Logo.svg';
import bgImage360 from './assets/bg-360.webp';
import bgImage768 from './assets/bg-768.webp';
import bgImage1024 from './assets/bg-1024.webp';
import bgImage1200 from './assets/bg-1200.webp';
import './styles/App.scss';
import {lazy, Suspense, useState} from "react";
import {AppContext} from "./context";
import {useProgressiveImage} from "./hooks/useProgressiveImage";

const Header = lazy(() => import(/* webpackChunkName: "Header" */ './components/Header'));
const Banner = lazy(() => import(/* webpackChunkName: "Banner" */ './components/Banner'));
const Cards = lazy(() => import(/* webpackChunkName: "Cards" */ './components/Cards'));
const Form = lazy(() => import(/* webpackChunkName: "Form" */ './components/Form'));

function App() {
    const [isFormSend, setIsFormSend] = useState(false);

    const bg360 = useProgressiveImage(bgImage360);
    const bg768 = useProgressiveImage(bgImage768);
    const bg1024 = useProgressiveImage(bgImage1024);
    const bg1200 = useProgressiveImage(bgImage1200);
    const loadedLogo = useProgressiveImage(logo);

    const headerData = {
        logo: loadedLogo,
        buttons: [
            {text: 'Users', link: '#users'},
            {text: 'Sign up', link: '#signup'},
        ]
    }

    const bannerData = {
        headline: 'Test assignment for front-end developer',
        text: 'What defines a good front-end developer is one that has skilled knowledge of HTML, CSS, JS with a vast understanding of User design thinking as they\'ll be building web interfaces with accessibility in mind. They should also be excited to learn, as the world of Front-End Development keeps evolving.',
        buttonText: 'Sign up',
        image: {bg360, bg768, bg1024, bg1200}
    }

    return (
        <AppContext.Provider value={{ isFormSend, setIsFormSend }}>
            <div className="App">
                <Suspense fallback={<div className="text-center" style={{ height:120 }}>Loading...</div>}>
                    <Header {...headerData}/>
                </Suspense>
                <Suspense fallback={<div className="text-center" style={{ height:450 }}>Loading...</div>}>
                    <Banner {...bannerData}/>
                </Suspense>
                <Suspense fallback={<div className="text-center" style={{ height:450 }}>Loading...</div>}>
                    <Cards/>
                </Suspense>
                <Suspense fallback={<div className="text-center" style={{ height:450 }}>Loading...</div>}>
                    <Form/>
                </Suspense>
            </div>
        </AppContext.Provider>
    );
}

export default App;
